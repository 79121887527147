<template>
  <b-modal
    id="change-group-modal"
    :visible="show"
    ref="change-group-modal"
    title="Thay đổi nhóm"
    centered
    size="xl"
    hide-footer
    @hide="resetModal"
  >
    <b-container fluid class="px-0">
      <b-row class="mb-2">
        <b-col cols="4">
          <basic-input
            label="Nhóm"
            name="group-search"
            placeholder="Nhập mã hoặc tên nhóm"
            :value.sync="filters.groupSearch"
            :debounce="2000"
            @v-modal:update="searchGroup"
          />
        </b-col>
        <b-col cols="4">
          <basic-input
            label="Coach"
            name="coach-search"
            placeholder="Nhập mã hoặc tên coach"
            :value.sync="filters.healthCoachSearch"
            :debounce="2000"
            @v-modal:update="searchCoach"
          />
        </b-col>
      </b-row>
      <b-overlay :show="loading">
        <TableGroup
          :groups="groups"
          :paging="paging"
          @select="select"
          @sortBy="sortRequest"
          @open-modal-mapping="openModalMapping"
        />
      </b-overlay>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-end align-items-center">
            <b-button class="btn" type="button" @click="cancelModal">
              Huỷ
            </b-button>
            <b-button
              class="btn btn-success ml-3"
              type="button"
              :disabled="!selectedGroup"
              @click="submit"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { CHANGE_GROUP, GET_PREPARE } from '@/core/services/store/coach.module';
const { mapActions } = createNamespacedHelpers('coach');
export default {
  name: 'ModalChangeGroup',
  components: {
    TableGroup: () => import('./TableGroup.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        groupSearch: null,
        healthCoachSearch: null,
      },
      loading: false,
      groups: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 10,
      },
      selectedGroup: null,
      sort: {
        by: null,
        order: null,
      },
    };
  },
  computed: {
    searchParams() {
      return {
        groupSearch: this.filters.groupSearch || null,
        healthCoachSearch: this.filters.healthCoachSearch || null,
        page: this.paging.page,
        size: this.paging.pageSize,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        IsGroupsCanJoin: true,
        accountPatientId: this.id,
        prefetchCriteriaMapping: true,
      };
    },
  },
  watch: {
    'paging.page': function () {
      this.getGroups();
    },
    'paging.pageSize': function () {
      this.getGroups();
    },
  },
  created() {
    this.getGroups();
  },
  methods: {
    ...mapActions({ CHANGE_GROUP, GET_PREPARE }),
    searchGroup(value) {
      this.filters.groupSearch = value;
      this.resetPaging();
    },
    searchCoach(value) {
      this.filters.healthCoachSearch = value;
      this.resetPaging();
    },
    resetModal() {
      this.selectedGroup = null;
      this.$emit('reset');
    },
    cancelModal() {
      this.$bvModal.hide('change-group-modal');
    },
    async submit() {
      this.loading = true;
      const params = {
        accountId: this.id,
        trainingGroupId: this.selectedGroup,
      };
      const { error } = await this.CHANGE_GROUP(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        await this.getGroupDetail();
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Thay đổi nhóm thành công',
        });
        this.cancelModal();
      }
      this.loading = false;
    },
    async getGroups() {
      this.loading = true;
      try {
        const params = this.searchParams;
        const { data, meta } = await this.$api.get('/TrainingGroup', {
          params,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
          });
        }
        const groupId = this.$route.params?.id;
        data.length &&
          (this.groups = [...data].filter((el) => el.id !== groupId));
        this.paging.total = meta.total - 1;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Server không response',
        });
      } finally {
        this.loading = false;
      }
    },
    resetPaging() {
      this.paging.page = 1;
      if (this.paging.page === 1) {
        this.getGroups();
      }
    },
    select(params) {
      this.selectedGroup = params;
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return this.getGroups();
    },
    async getGroupDetail() {
      await this.GET_PREPARE({ id: this.$route.params.id });
    },
    openModalMapping(payload) {
      this.$emit('open-modal-mapping', payload);
    },
  },
};
</script>
